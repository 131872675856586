.login-form{
    box-sizing: border-box;
    width: 350px;
    padding: 30px 30px;
    margin-left: 30px;
    background-color: #006eff;

    h1{
        text-align: left;
        color: #fff;
    }

    .code-box{
        position: relative;
        .get-code{
            position: absolute;
            right: 0;
            top: 0;
            padding: 5px;
            background-color: #ececec;
            z-index: 100;
        }
    }

    .name{
        padding: 10px 0;
        font-weight: bold;
    }


    .login-button{
        background-color: #ff9138;
        width: 100%;
        margin-top: 40px;
    }

    .login-remind{
        padding: 5px 0;
        text-align: center;
        color: #fff;
    }
}