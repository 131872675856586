@import '~antd/dist/antd.css';

#root{
  height: 100vh;
  overflow: hidden;
}
:root{
  --page-main-padding: 10px;
  --page-main-bgColor: #fff;
  --el-left-menu-width: 200px;
  --el-color-transition: #1890ff;
  --el-border-radius-base: 2.5px;
  --el-color-info-lighter: #f4f4f5;
  --el-color-info-light: #e9e9eb;
  --el-color-error-lighter: #ffeded;
  --el-color-error-light: #ffdbdc;
  --el-color-error: #ff4d4f;
  --el-color-danger-lighter: #ffeded;
  --el-color-danger-light: #ffdbdc;
  --el-color-danger: #ff4d4f;
  --el-color-warning-lighter: #fff8e6;
  --el-color-warning-light: #fff1cc;
  --el-color-warning: #ffba00;
  --el-color-success-lighter: #e7faf0;
  --el-color-success-light: #d0f5e0;
  --el-color-success: #13ce66;
  --el-color-primary-light-9: #f0eefe;
  --el-color-primary-light-8: #e1ddfc;
  --el-color-primary-light-7: #d2ccfb;
  --el-color-primary-light-6: #c3bbf9;
  --el-color-primary-light-5: #b4aaf8;
  --el-color-primary-light-4: #a598f6;
  --el-color-primary-light-3: #9687f5;
  --el-color-primary-light-2: #8776f3;
  --el-color-primary-light-1: #7865f2;
  --el-color-primary: #6954f0;
  --el-color-black: #515a6e;
  --el-color-grey: rgba(0, 0, 0, 0.65);
  --el-border-radius: 2.5px;
}

p{
  margin: 0;
  padding: 0;
}

ul{
  list-style: none;
  margin: 0;
  padding: 0;
}